@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles */
body {
  @apply bg-black text-white font-bold font-sans;  /* Black background, white bold text, sans-serif font */
  -webkit-font-smoothing: antialiased; /* Smooth font rendering */
  -moz-osx-font-smoothing: grayscale; /* Smooth font rendering for Firefox on Mac */
}

/* Specific styles for your elements */
.nav-button-demo {
  @apply bg-blue-900 text-white font-bold px-4 py-2 text-center inline-block rounded-md;
}

.nav-button-demo:hover {
  @apply bg-blue-700;
}

.nav-button {
  @apply bg-gray-700 text-white font-bold px-4 py-2 text-center inline-block rounded-md;
}

.nav-button-entrar {
  @apply bg-white text-blue-900 font-bold border-2 border-blue-800;
}

.nav-button-entrar:hover {
  @apply bg-gray-200;
}

.nav-center {
  @apply flex justify-center items-center w-full text-blue-900;
}

.newsletter-container {
  @apply transition-all duration-500;
}

.modal {
  @apply fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50;
}

.modal-content {
  @apply bg-blue-900 mx-auto p-6 rounded-lg max-w-md w-full text-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.close {
  @apply text-gray-400 text-2xl font-bold cursor-pointer;
}

.close:hover {
  @apply text-white;
}

.footer {
  @apply bg-blue-900 text-white;
}

.chat-container {
  @apply max-w-lg mx-auto border-2 border-gray-700 rounded-lg overflow-hidden;
}

.chat-window {
  @apply h-72 overflow-y-scroll p-4;
}

.message-content {
  @apply p-4 rounded-md bg-gray-800;
}

.user .message-content {
  @apply bg-blue-800 text-right;
}
